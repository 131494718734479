<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on: menu, attrs }">
      <v-tooltip transition="fade-transition" bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            :loading="loading"
            dark
            class="text-uppercase"
            depressed
            v-bind="{ ...attrs, ...btnProps }"
            v-on="{ ...menu, ...tooltip }"
          >
            {{ statusShow }}
          </v-btn>
        </template>

        <span>Set procedure status</span>
      </v-tooltip>
    </template>

    <v-list nav>
      <v-list-item
        link
        v-for="(state, index) in states"
        :key="index"
        @click="setState(state)"
      >
        <v-list-item-title class="text-capitalize">{{
          state.label
        }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { getAPI } from "@/api/axios-base";
import { notifyError } from "@/components/Notification";
import Vue from "vue";
import { mapActions } from "vuex";

const stateBase = "select";

export default Vue.extend({
  name: "btn-procedure-status",
  props: {
    procedure: Object,
    btnProps: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      state: null,
      statename: null,
      loading: false,
      states: [],
    };
  },
  watch: {
    procedure: {
      deep: true,
      immediate: true,
      handler(procedure) {
        this.state = procedure.statusNom;
        this.statename = procedure.statusNom ? procedure.statusNom.name : "-";
      },
    },
  },
  computed: {
    statusShow() {
      if (this.state != null && this.state != undefined) {
        return this.state.name
          ? this.state.name.replaceAll("_", " ").toLowerCase()
          : "";
      }
      if (this.statename != null && this.statename != undefined) {
        return this.statename.replaceAll("_", " ").toLowerCase();
      }
      return "";
    },
  },

  async mounted() {
    await this.getStatus();
  },
  methods: {
    ...mapActions("crmDashboardModule", ["actUpdateProcedureStatus"]),
    setState(state) {
      if (state == this.state) return;
      this.state = state;
      this.loading = true;

      this.actUpdateProcedureStatus({
        id: this.procedure.id,
        status: state.id,
      })
        .then(() => {
          this.loading = false;
          this.$emit("updated");
        })
        .catch((error) => {
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);
          this.loading = false;
        });

      this.$emit("state", state);
    },

    async getStatus() {
      const response = (await getAPI(`/procedure-status-nom/list`)).data;
      const filt = response.filter((r) => r.status != "NO_ACTIVE");

      this.states = filt.map((res) => {
        return {
          label: res.name.replaceAll("_", " ").toLowerCase(),
          value: res.name,
          id: res.id,
        };
      });
    },
  },
});
</script>
